import axios from 'axios'

const API = 'delivery/novedades'

const NovedadesChat = {
    listarChatsNovedades(id_pedido){
        return axios(`${API}/${id_pedido}/chat-novedades`)
    },
    enviarMensajeChat(params){
        return axios.post(`${API}/enviar-chat-novedades`,params)
    },
    enviarImagenChat(form){
        return axios.post(`${API}/enviar-imagen-novedades`,form)
    },
    finalizarNovedad(id_pedido){
        return axios(`${API}/${id_pedido}/finalizar-novedad`)
    }
}

export default NovedadesChat
