<template>
    <!-- Momento 2 es reclamo -->
    <section>
        <div class="row mx-0 justify-content-end py-2">
            <div v-if="pedido.novedad == 1" class="bg-general text-white px-3 py-1 br-12 cr-pointer" @click="$refs.modalCerrar.toggle()">
                Finalizar Novedad
            </div>
        </div>
        <div id="scrollGeneral" class="custom-scroll overflow-auto" style="height:calc(100vh - 389px)">
            <div v-for="(chat, index) in datos.chat" :key="index" class="row mx-0 px-2 my-3 no-gutters">
                <div class="col-8 ml-1 mr-2" :class="datos.admin == chat.created_by? 'ml-auto' : 'mr-auto'">
                    <div class="row mx-0">
                        <div class="col-auto px-0 br-5 chat-bubble" :class="datos.admin == chat.created_by ? 'chat-bubble-right ml-auto' : 'chat-bubble-left' ">
                            <div v-show="true" class="row mx-0 my-1 f-13">
                                <div class="col">
                                    <div class="row mx-0 justify-content-end">
                                        <i class="text-general2" v-text="`${chat.nombre_user}`" />
                                    </div>
                                    <div v-if="chat.tipo == 1" class="row mx-0 justify-content-end">
                                        <p class="f-15 text-general" style="word-break: break-all;white-space: break-spaces;">
                                            {{ chat.mensaje }}
                                        </p>
                                    </div>
                                    <div v-else class="row mx-0 justify-content-end">
                                        <p class="f-15 text-general" style="word-break: break-all;white-space: break-spaces;">
                                            <img :src="chat.mensaje" style="height:250px;width:250px" />
                                        </p>
                                    </div>

                                    <div class="row mx-0 justify-content-end">
                                        <i class="text-general2" v-text="`${formatearFecha(chat.created_at,'DD-MM-YYYY H:m')}`" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="datos.admin == chat.created_by" class="chat-bubble-arrow-right" />
                            <div v-else class="chat-bubble-arrow-left" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 p-2 position-relative" style="bottom:0;height:50px;">
            <div class="w-100 d-flex align-items-center mx-0 position-absolute px-3" style="bottom:5px;left:0px">
                <div class="col-12 px-0">
                    <div class="position-relative bg-white br-20 d-middle container-button">
                        <el-input
                        v-model="mensaje"
                        type="textarea"
                        :autosize="{minRows: 0, maxRows: 1}"
                        class="input-chat"
                        placeholder="Digita el mensaje..."
                        @keydown.native="detectarEnter($event)"
                        />
                        <el-upload
                        ref="adjuntos"
                        class="upload-demo"
                        action="#"
                        :limit="1"
                        :on-change="agregarArchivos"
                        :auto-upload="false"
                        accept="image/*"
                        >
                            <i class="icon-attach text-general2 cr-pointer" />
                        </el-upload>
                    </div>
                </div>
            </div>
        </div>

        <modalConfirmar ref="modalCerrar" titulo="Finalizar Novedad" icon="checked" mensaje="¿Desea cerrar esta conversación?" @guardar="finalizarNovedad" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import NovedadesChat from '~/services/chat/novedades'

export default {
    data(){
        return{
            mensaje:'',
            datos: []
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
        }),
    },
    mounted(){
        this.loadMensajes()
        this.scroll()
    },
    methods: {
        async loadMensajes(){
            try {
                const {data} = await NovedadesChat.listarChatsNovedades(this.id_pedido)
                this.datos = data
                this.restablecerScroll()
            } catch (error){
                this.error_catch(error)
            }
        },

        async agregarArchivos(file, fileList){
            let tamano = null
            if (file.raw.type.split('/').slice(0, 1).pop() === 'image'){
                tamano = 1000
            } else {
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple con la extensión requerida(imágen)', 'warning')
                return
            }

            const limite = file.size / 1024 / 1024 <= tamano
            if (!limite){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', ` El archivo supera los ${tamano} Mb`, 'warning')
                return
            }

            this.archivo = file
            fileList.splice(fileList.indexOf(file), 1)

            this.enviarImagen(this.archivo)


        },
        detectarEnter(key){
            let code = key.keyCode
            if(code === 13){
                this.enviarMensaje()
            }
        },
        async enviarMensaje(){
            try {
                let params = {
                    id_admin_pedido: this.id_pedido,
                    mensaje: this.mensaje,
                    tipo: 1
                }
                const { data } = await NovedadesChat.enviarMensajeChat(params)
                this.mensaje = null
                this.loadMensajes()
            } catch (e){
                this.error_catch(e)
            }
            
        },
        async enviarImagen(){
            try {
                let params = {
                    id_admin_pedido: this.id_pedido,
                    mensaje: this.archivo.raw,
                    tipo: 2
                }
                const form = this.crear_formData(params)
                const { data } = await NovedadesChat.enviarImagenChat(form)
                this.loadMensajes()
            } catch (e){
                this.error_catch(e)
            }
        },
        async finalizarNovedad(){
            try {
                try {
                    const { data } = await NovedadesChat.finalizarNovedad(this.id_pedido)
                    this.pedido.novedad = 0
                    this.$emit('update')
                    this.$refs.modalCerrar.toggle()
                } catch (e){
                    this.error_catch(e)
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        restablecerScroll(){
            var  element = document.getElementById('scrollGeneral')

            if(element){
                if(element.scrollTo){
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: "smooth"
                    })
                }
            }
        }
    }
}
</script>

<style lang="css" scoped>
.chat-general{
    /* width:28vw; */
    height:100%;
    /* padding-bottom: 90px; */
}
.bg-chat{
    background: #F0F0F0;
}
.chat-bubble{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 1px solid #F8FCFF;
}
.chat-bubble-left{
    background: #F5F5F5;
    /* border: 1px solid var(--color-general); */
    /* border: 2px solid #0892DD; */
}
.chat-bubble-right{
    background: #F5F5F5;
    /* border: 1px solid red; */
    color: var(--text-general)!important;
}
.chat-bubble-arrow-left {
    border-color: #F5F5F5 #00000000  #00000000 transparent;
    border-style: solid;
    border-width: 10px;
    top: 0px;
    height: 0;
    right: -10px;
    position: absolute;
    width: 0;
}
.chat-bubble-arrow-right {
    border-color: #F5F5F5 #00000000  #00000000 transparent;
    border-style: solid;
    border-width: 14px;
    top: 0px;
    height: 0;
    right: -10px;
    position: absolute;
    width: 0;
}
.img-chat{
    object-fit: cover;
    width:200px;
    max-width:100%;
    max-height: 200px;
    /* max-height:100%; */
}
.card-archivo{
        max-width: 231px;
}
.icono-subir{
    position: absolute;
    top: -7px;
    right: 5px;
}
 /* bonton se subir */
 .scrollToTopBtn2 {
    background-color: #ffffff;
    opacity: 0.8;
    border: none;
    border-radius: 50%;
    position: fixed;
    right: 23px;
    top: 5px;
    cursor: pointer;
    font-size: 16px;
    /* line-height: 48px; */
    width: 48px;
    height: 48px;

    /* keep it at the top of everything else */
    z-index: 100;
    /* hide with opacity */
    opacity: 0;
    /* also add a translate effect */
    transform: translateY(100px);
    /* and a transition */
    transition: all .5s ease;
    border: 1px solid #DBDBDB;
 }
.showBtn {
  opacity: 1;
  transform: translateY(0)
}
.container-button{
    border:1px solid  #C0C4CC;
}
</style>
